



























































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class SupplierProductDebug extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  // i18n is not initialized here, so add rules at created function
  private rules = {}

  private loadingInitialData = true
  private getDataLoading = false
  private gotData = false
  private mainMessage = null
  private supplierInputContent = null

  private filteringPanel = [0]

  private suppliers = []

  private filteringSupplierId = null
  private filteringSupplierProductId = null

  protected created(): void {
    this.rules = {
      supplier: [(v) => !!v || this.$t('c:validation:This field is required')],
      supplierProductId: [(v) => !!v || this.$t('c:validation:This field is required')]
    }

    this.$axios
      .get('/v3/suppliers/product/debug/get_initial_data')
      .then((response) => {
        this.suppliers = response.data.data.suppliers
      })
      .finally(() => {
        this.loadingInitialData = false
      })
  }

  private getData(): void {
    if (this.$refs.form.validate()) {
      this.getDataLoading = true
      const data = {
        supplierId: this.filteringSupplierId,
        supplierProductId: this.filteringSupplierProductId,
      }
      this.$axios
        .post('/v3/suppliers/product/debug/get_data', data)
        .then((response) => {
          this.mainMessage = response.data.data.mainMessage
          this.supplierInputContent = response.data.data.supplierInputContent
          this.gotData = true
        })
        .finally(() => {
          this.getDataLoading = false
        })
    }
  }
}
